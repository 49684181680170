import request from '@/utils/request'

// 单xlsx文件上传OOS并生成导入计划
export function importTpl(data) {
  return request({
    url: '/baseAdmin/common/one-upload-as-save',
    method: 'post',
    data,
  })
}

// 导入任务执行列表
export function queryData(data) {
  return request({
    url: '/baseAdmin/import/list',
    method: 'post',
    data,
  })
}

// 导出导入执行结果 文件下载
export function getEnd(data) {
  return request({
    responseType: 'blob',

    url: '/baseAdmin/import/export-result',
    method: 'post',
    data,
  })
}

// 盘点单导入

export function downLoadPandian(data) {
  return request({
    responseType: 'blob',

    url: '/baseAdmin/import/export-result',
    method: 'post',
    data,
  })
}
